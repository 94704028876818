<template>
  <div>
    <div class="partner-tenant-login-content-container">
      <section class="partner-tenant-login-description-section">
        <div class="partner-tenant-login-description-text-container">
          <h3 class="partner-tenant-login-description-title">Get ready to reach your academic and career potential.</h3>
        </div>
        <img
          src="https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/Log-In-Illustration.webp"
          class="partner-tenant-login-description-image"
          alt="Login image"
        />
      </section>
      <section class="partner-tenant-login-form-section">
        <div class="partner-tenant-login-sign-up-button-container">
          Don't have an account?
          <router-link to="/accounts/register" class="partner-tenant-login-sign-up-button">Sign Up now</router-link>
        </div>
        <form
          v-show="!forgotPasswordFormActive && !accountConfirmedMessageActive"
          @submit.prevent="submit_form"
          class="partner-tenant-login-form-container"
        >
          <div class="partner-tenant-login-form-title-text-container">
            <h3 v-if="!is_sso_form" class="partner-tenant-login-form-title">Sign In to BeGlobalii</h3>
            <h3 v-else class="partner-tenant-login-form-title">Welcome to BeGlobalii</h3>
          </div>
          <div class="partner-tenant-login-form-title-text-container">
            <p v-if="is_sso_form" class="partner-tenant-login-description-text-dark">
              Please take a moment to complete your account.
            </p>
          </div>
          <div v-if="show_signin_buttons" class="partner-tenant-login-sso-buttons-container" style="height: 2.75rem">
            <div id="google_signin_button"></div>
            <!-- <button class="partner-tenant-login-sso-button">
              <img src="https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/Google.webp" alt="Google logo">
            </button>
            <button class="partner-tenant-login-sso-button">
              <img src="https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/Facebook.webp" alt="Facebook logo">
            </button>
            <button class="partner-tenant-login-sso-button">
              <img src="https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/LinkedIn.webp" alt="Linkedin logo">
            </button> -->
          </div>
          <div v-if="show_signin_buttons" class="partner-tenant-login-separator">
            <span class="partner-tenant-login-separator-line"></span>
            <span class="partner-tenant-login-separator-text">Or</span>
            <span class="partner-tenant-login-separator-line"></span>
          </div>
          <div v-if="!is_sso_form" class="partner-tenant-login-input-wrapper">
            <div class="partner-tenant-login-input-label-wrapper">
              <label for="email" class="partner-tenant-login-input-label">Email <span class="required">*</span></label>
            </div>
            <input
              v-model="user_data.email"
              type="email"
              placeholder="Email"
              class="partner-tenant-login-input"
              :class="errors.email ? 'error' : ''"
              id="email"
            />
            <div v-if="errors.email" class="partner-tenant-login-input-error-message">
              {{ errors.email }}
            </div>
            <div v-if="errors.email_validation" class="partner-tenant-login-input-error-message action-error-message">
              Your account has not been validated. Check your email or click
              <a @click="resendValidationEmail()">HERE</a> to resend the validation email.
            </div>
          </div>
          <div v-if="!is_sso_form" class="partner-tenant-login-input-wrapper">
            <div class="partner-tenant-login-input-label-wrapper">
              <label for="password" class="partner-tenant-login-input-label"
                >Password <span class="required">*</span></label
              >
              <div
                @click.prevent="showForgotPasswordForm"
                tabindex="0"
                aria-label="Open reset password form"
                class="partner-tenant-login-forgot-password-button"
              >
                Forgot Password?
              </div>
            </div>
            <input
              v-model="user_data.password"
              type="password"
              placeholder="Password"
              class="partner-tenant-login-input"
              :class="errors.password ? 'error' : ''"
              id="password"
            />
            <div v-if="errors.password" class="partner-tenant-login-input-error-message">
              {{ errors.password }}
            </div>
          </div>
          <div v-if="is_sso_form" class="partner-tenant-login-input-wrapper">
            <div class="partner-tenant-login-input-label-wrapper">
              <label for="first_name" class="partner-tenant-login-input-label"
                >First Name <span class="required">*</span>
              </label>
            </div>
            <input
              v-model="user_data.first_name"
              type="text"
              placeholder="First Name"
              class="partner-tenant-login-input"
              id="first_name"
              :class="errors.first_name ? 'error' : ''"
            />
            <div v-if="errors.first_name" class="partner-tenant-login-input-error-message">
              {{ errors.first_name }}
            </div>
          </div>
          <div v-if="is_sso_form" class="partner-tenant-login-input-wrapper">
            <div class="partner-tenant-login-input-label-wrapper">
              <label for="last_name" class="partner-tenant-login-input-label"
                >Last Name <span class="required">*</span></label
              >
            </div>
            <input
              v-model="user_data.last_name"
              type="text"
              placeholder="Last Name"
              class="partner-tenant-login-input"
              :class="errors.last_name ? 'error' : ''"
              id="last_name"
            />
            <div v-if="errors.last_name" class="partner-tenant-login-input-error-message">
              {{ errors.last_name }}
            </div>
          </div>
          <div v-if="is_sso_form" class="partner-tenant-sign-up-student-checkbox-wrapper">
            <input
              v-model="user_data.isAgreedTerms"
              id="terms"
              type="checkbox"
              class="partner-tenant-sign-up-student-checkbox"
              :class="errors.isAgreedTerms ? 'error' : ''"
            />
            <label for="terms" class="partner-tenant-sign-up-student-checkbox-label">
              Creating an account means you’re okay with our
              <a href="/terms">
                <u>Terms of Service</u>
              </a>
              and
              <a href="/privacy">
                <u>Privacy Policy</u>
              </a>
              .
            </label>
            <div
              v-if="errors.isAgreedTerms"
              class="partner-tenant-login-input-error-message"
              style="position: relative; top: 0"
            >
              {{ errors.isAgreedTerms }}
            </div>
          </div>
          <div>
            <button type="submit" class="partner-tenant-login-submit-button">Sign In</button>
          </div>
        </form>
        <div v-show="accountConfirmedMessageActive" class="partner-tenant-login-account-confirmed-content-container">
          <div class="partner-tenant-login-account-confirmed-text-container">
            <h3 class="partner-tenant-login-account-confirmed-title">Welcome to BeGlobalii!</h3>
            <p class="partner-tenant-login-account-confirmed-description">
              Thank you for validating your account. You may now log in.
            </p>
          </div>
          <div>
            <button @click.prevent="hideAccountConfirmedMessage()" class="partner-tenant-login-submit-button">
              Log In
            </button>
          </div>
        </div>
        <form v-show="forgotPasswordFormActive" class="partner-tenant-login-forgot-password-form">
          <div class="text-container">
            <h3 class="title">Forgot password?</h3>
            <p class="description">
              Enter the email address you used to join BeGlobalii and we'll send you instruction to reset your password.
            </p>
          </div>
          <div class="partner-tenant-login-input-wrapper">
            <div class="partner-tenant-login-input-label-wrapper">
              <label for="reset-email" class="partner-tenant-login-input-label">Email address</label>
            </div>
            <input
              v-model="reset_email"
              type="email"
              placeholder="Enter your email"
              class="partner-tenant-login-input"
              :class="errors.reset_email ? 'error' : ''"
              id="reset-email"
            />
            <div v-if="errors.reset_email" class="partner-tenant-login-input-error-message">
              {{ errors.reset_email }}
            </div>
          </div>
          <div style="width: 100%">
            <button @click.prevent="submit_reset_email" class="partner-tenant-login-submit-button">
              Send Reset Instructions
            </button>
          </div>
        </form>
        <div class="partner-tenant-login-sign-up-button-container mobile-only">
          Don't have an account?
          <router-link to="/accounts/register" class="partner-tenant-login-sign-up-button">Sign Up now</router-link>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import AuthService from '../../services/AuthService';
import AccountService from '../../services/AccountService';
import Cookies from 'js-cookie';
import { useNotificationStore } from '../../../stores/notification';
import { isValidEmailString } from '../../../utils/CustomUtils';

export default {
  name: 'partner-tenant-login',
  props: ['google_client_id', 'google_sso_redirect_uri', 'google_sso_token'],
  data() {
    return {
      user_data: {
        email: '',
        password: '',
        code: '',
        first_name: '',
        last_name: '',
        isAgreedTerms: false,
      },
      reset_email: '',
      errors: {
        email: '',
        password: '',
        email_validation: '',
        first_name: '',
        last_name: '',
        isAgreedTerms: '',
        reset_email: '',
      },
      show_signin_buttons: true,
      is_sso_form: false,
      authService: new AuthService(),
      accountService: new AccountService(),
      notificationStore: useNotificationStore(),
      headers: {
        'X-CSRFToken': Cookies.get('csrftoken'),
        Accept: 'application/json',
      },
      forgotPasswordFormActive: false,
      accountConfirmedMessageActive: false,
    };
  },
  mounted() {
    this.initializeGoogleSSO();
    const login_button = document.getElementById('login-header-button');

    if (login_button) {
      login_button.addEventListener('click', () => {
        this.forgotPasswordFormActive = false;
      });
    }
  },
  created() {
    if (this.google_sso_token) {
      this.user_data.code = this.google_sso_token;
      this.verifyGoogleSSOToken(this.user_data.code);
    }
    if (this.$route.path.includes('confirmed')) {
      this.accountConfirmedMessageActive = true;
    }
  },
  methods: {
    hideAccountConfirmedMessage() {
      this.accountConfirmedMessageActive = false;
      this.$router.push('login');
    },
    submit_reset_email() {
      this.$Progress.start();
      if (isValidEmailString(this.reset_email)) {
        this.errors.reset_email = '';
        this.accountService
          .sendResetPasswordEmail({ email: this.reset_email }, this.headers, true)
          .then((response) => {
            if (response.success) {
              this.notificationStore.addNotification('Please check your email inbox for reset instructions.', 10000);
              this.forgotPasswordFormActive = false;
              this.$Progress.finish();
            } else {
              this.notificationStore.addNotification('Please verify the email you provided.', 10000);
              this.showError('invalid_reset_email');
              this.$Progress.fail();
            }
          })
          .catch((error) => {
            this.notificationStore.addNotification('Please verify the email you provided.', 10000);
            this.showError('invalid_reset_email');
            this.$Progress.fail();
          });
      } else {
        this.showError('invalid_reset_email');
        this.notificationStore.addNotification('Please verify the email you provided.', 10000);
        this.$Progress.fail();
      }
    },
    showForgotPasswordForm() {
      this.forgotPasswordFormActive = true;
    },
    initializeGoogleSSO() {
      let script = document.createElement('script');
      script.onload = () => {
        window.google.accounts.id.initialize({
          client_id: this.google_client_id,
          ux_mode: 'redirect',
          login_uri: this.google_sso_redirect_uri,
        });
        window.google.accounts.id.renderButton(document.getElementById('google_signin_button'), {
          type: 'standard',
          shape: 'pill',
          theme: 'outline',
          size: 'large',
        });
      };
      script.async = true;
      script.defer = true;
      script.src = 'https://accounts.google.com/gsi/client';
      document.head.appendChild(script);
    },
    renderGoogleSSOButton() {
      window.google.accounts.id.renderButton(document.getElementById('google_signin_button'), {
        type: 'standard',
        shape: 'pill',
        theme: 'outline',
        size: 'large',
      });
    },
    verifyGoogleSSOToken(code) {
      this.errors.email = '';
      this.errors.email_validation = '';
      this.$Progress.start();
      this.accountService
        .verifyGoogleToken({ code: code }, this.headers, true)
        .then((verifyResponse) => {
          if (verifyResponse.status === 'user_exist') {
            this.accountService
              .loginWithGoogleToken({ code: code }, this.headers, true)
              .then((loginResponse) => {
                window.location.href = '/login';
                this.$Progress.finish();
              })
              .catch((error) => {
                this.$Progress.fail();
              });
          } else if (verifyResponse.status === 'user_does_not_exist') {
            this.is_sso_form = true;
            this.show_signin_buttons = false;
            this.user_data.first_name = verifyResponse.social_profile_data.first_name;
            this.user_data.last_name = verifyResponse.social_profile_data.last_name;
          } else {
            this.$Progress.fail();
            this.showError('invalid_response');
          }
        })
        .catch((error) => {
          this.$Progress.fail();
          this.showError(error.responseObject.error);
        });
    },
    submit_form() {
      this.$Progress.start();
      if (this.is_sso_form) {
        this.accountService
          .registerAndLoginWithGoogleToken({ new_account: true, ...this.user_data }, this.headers, true)
          .then((loginResponse) => {
            this.$Progress.finish();
            window.location.href = '/login';
          })
          .catch((error) => {
            this.$Progress.fail();
            this.showError(error.responseObject.error);
          });
      } else {
        this.authService
          .login(this.user_data, this.headers, true)
          .then((data) => {
            this.$Progress.finish();
            if (data && data.success) {
              window.location.pathname = data.next;
            } else {
              this.errors.password = 'Email or password you provided is invalid.';
            }
          })
          .catch((error) => {
            this.$Progress.fail();
            this.showError(error.responseObject.error, error.responseObject.message);
          });
      }
    },
    showError(error, errorMsg) {
      switch (error) {
        case 'account_suspended':
          this.errors.email =
            'Your account has been suspended, and your access to the site has been restricted. Contact the site administrator for further details.';
          break;
        case 'license_expired':
          this.errors.email = errorMsg;
          break;
        case 'account_not_validated':
          this.errors.email_validation =
            'Your account has not been validated. Please click on the validation link sent to the email account you registered with.';
          break;
        case 'user_exist_with_another_channel': {
          this.errors.email = 'That email address is associated with an existing BeGlobalii account';
          break;
        }
        case 'terms_of_service': {
          this.errors.isAgreedTerms =
            'Agreeing to Terms of Service and Privacy Policy is necessary for creating an account.';
          break;
        }
        case 'invalid_google_token':
          this.errors.email = 'There was an error processing your request, please try again. (TOKEN)';
          break;
        case 'invalid_response':
          this.errors.email = 'There was an error processing your request, please try again.';
          break;
        default:
          this.errors.password = 'Email or password you provided is invalid.';
          break;
        case 'invalid_reset_email':
          this.errors.reset_email = 'Please insert a valid password.';
          break;
        case 'invalid_reset_email_response':
          this.errors.reset_email = errorMsg;
          break;
      }
    },
    async resendValidationEmail() {
      this.accountService
        .resendValidationEmail(this.user_data, this.headers, true)
        .then((data) => {
          this.notificationStore.addNotification('Please check your email inbox and verify your account.', 10000);
        })
        .catch((error) => {});
    },
  },
};
</script>
