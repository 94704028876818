export function getBlogAuthorProfilePictureUrl(author) {
  let url = '/static/assets/icons/default-profile.svg';

  if (author.profilePic && author.profilePic.url) {
    url = author.profilePic.url;
  }

  return url;
}

export function getMetaConfig() {
  if (!(window as any).metaConfig) return;

  return JSON.parse((window as any).metaConfig.textContent);
}

export function getRouteMetaData(metaConfig, metaConfigUrlKey) {
  if (!metaConfig) return;

  let context = metaConfig.urls.default ? { ...metaConfig.urls.default } : {};

  if (metaConfigUrlKey && metaConfig.urls.hasOwnProperty(metaConfigUrlKey)) {
    context = { ...context, ...metaConfig.urls[metaConfigUrlKey] };
  }

  context = Object.entries(context).reduce((acc, [key, value]) => {
    if (value !== null && value !== undefined) {
      acc[key] = value;
    }

    return acc;
  }, {});

  return context;
}

export function updateOrCreateMetaTag(name, content) {
  let metaTag = document.querySelector(`meta[name="${name}"]`);

  if (metaTag) {
    metaTag.setAttribute('content', content);
  } else {
    metaTag = document.createElement('meta');
    metaTag.setAttribute('name', name);
    metaTag.setAttribute('content', content);
    document.head.appendChild(metaTag);
  }
}

export function deleteMetaTag(name) {
  let metaTag = document.querySelector(`meta[name="${name}"]`);

  if (metaTag) {
    metaTag.remove();
  }
}

export function bulkUpdateMetaTags(routeMetaData, metaConfigMetaKeys) {
  const updateOrCreateMetaKeys = metaConfigMetaKeys.filter((key) => Object.keys(routeMetaData).includes(key));
  const deleteMetaKeys = metaConfigMetaKeys.filter((key) => !updateOrCreateMetaKeys.includes(key));

  updateOrCreateMetaKeys.forEach((key) => updateOrCreateMetaTag(key, routeMetaData[key]));
  deleteMetaKeys.forEach((key) => deleteMetaTag(key));
}
